import { ChangeEvent } from "react"

export const ArticleTable = (props:any) => {
  const checkedHanlder = (e: ChangeEvent) => {
    if (e.target instanceof HTMLInputElement) {
      if (e.target.checked) {
        props.setSelectedData([...props.selectedData, e.target.value])
        e.target.parentNode?.parentElement?.parentElement?.classList.add('stripped')
      } else {
        const value = e.target.value
        props.setSelectedData(props.selectedData.filter((item:string)=>{return item !== value}))
        e.target.parentNode?.parentElement?.parentElement?.classList.remove('stripped')
      }
    }
  }

  return(
    <table className="centered responsive-table" key={props.article.key}>
        <thead>
          <tr className={props.article.products[0]?.danger ? "red lighten-4" : "grey lighten-4"}>
            <th colSpan={16}>
              <div style={{display: "flex", flexDirection: 'column'}}>
                <div>{props.article.key.toLocaleUpperCase()}</div>
                {props.article.products[0]?.danger && <div>(Опасный)</div>}
                {props.article.products.length > 0 && <a target="blank" href={props.article.products[0]?.link}>{props.article.products[0].link}</a>}

              </div>
            </th>
          </tr>
          <tr className={props.article.products[0]?.danger ? "red lighten-4" : "grey lighten-4"}>
              <th></th>
              <th>SKU</th>
              <th>Item</th>
              <th>Purity</th>
              <th>Lead-time</th>
              <th>CAS</th>
              <th>Manufacturer</th>
              <th>Quantity</th>
              <th>Unit</th>
              <th>
                <span>Shipping temp</span>
                <br />
                <span> (storage)</span>
              </th>
              <th>Price</th>
              <th>Price Unit</th>
          </tr>
        </thead>

        <tbody>
          {
            props.article.products.length === 0 && <tr><th colSpan={16} className="center-align">Данных по артикуру "{props.article.key.toLocaleUpperCase()}" не найдено</th></tr>
          }
          {
            props.article.products.sort((item1: any, item2: any)=>item1.price -item2.price).map((product: any)=>(
              <tr key={product.sku} className={props.selectedData.includes(product.sku.toLocaleLowerCase()) ? 'stripped' : ''}>
                <td>
                  <label>
                    <input
                      className="filled-in"
                      type="checkbox"
                      checked={props.selectedData.includes(product.sku.toLocaleLowerCase())}
                      onChange={checkedHanlder}
                      value={product.sku.toLocaleLowerCase()}
                    />
                    <span style={{ transform: 'scale(1.2)'}}></span>
                  </label>
                </td>
                <td>{product.sku.toLocaleUpperCase()}</td>
                <td>
                  <div style={{maxWidth: '25em', margin: 'auto'}}>{product.item}</div>
                </td>
                <td>{product.purity}</td>
                <td>{product.leadtime || '-'}</td>
                <td>{product.cas}</td>
                <td>{product.manufacturer}</td>
                <td>{product.quantity}</td>
                <td>{product.unit}</td>
                <td>{product.shipping_temp || '-'}</td>
                <td>{product.price || '-'}</td>
                <td>{product.price_unit || '-'}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
  )
}
