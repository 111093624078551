import { utils, writeFileXLSX } from 'xlsx'
import { cas, unit, leadtime_converter, purity, quantity, lead_time, shipping_temp, check_is_cold } from '../services/instruments'

interface productInterface {
  sku: string,
  item: string,
  purity: string,
  leadtime: string,
  cas: string,
  manufacturer: string,
  quantity: string,
  unit: string,
  shipping_temp: string,
  price: number,
  price_unit: string,
  danger: boolean
}

export default class ExportToXlsx {
  dataForExcel:Object[] = []

  constructor(data: []){
    data.forEach((product: productInterface)=> {
      this.dataForExcel.push({
        Supplier: 'Helen',
        SKU: product.sku.toLocaleUpperCase(),
        Item: product.item,
        Purity: purity(product.purity),
        'Lead-time': lead_time(product.leadtime),
        'Срок поставки': leadtime_converter(product.leadtime, product.manufacturer),
        CAS: cas(product.cas),
        Manufacturer: product.manufacturer.replace('CST', 'Cell Signaling Technology'),
        Quantity: quantity(product.quantity.toLocaleLowerCase()),
        Unit: unit(product.unit),
        'Shipping temp (storage)': shipping_temp(product.shipping_temp),
        'Тип - Холодовая химия': check_is_cold(product.shipping_temp),
        'Price amount': product.price || '',
        'Price units': product.price_unit || '',
        'Danger': product.danger ? "ДА" : 'Non chemical',
        'Formula': `${product.sku.toLocaleUpperCase()}∩${cas(product.cas)}∪${product.manufacturer.replace('CST', 'Cell Signaling Technology')}⊂Helen⊃${product.item}⊄${leadtime_converter(product.leadtime, product.manufacturer)}⊅${product.price || ''}⊆${product.price_unit}⊇${purity(product.purity)}∏${product.shipping_temp}∐${quantity(product.quantity.toLocaleLowerCase())}⨒${unit(product.unit)}⨗`
      })
    })
  }

  exportToFile(){
    const ws = utils.json_to_sheet(this.dataForExcel)
    ws["!cols"] = [
      {wch: 8},
      {wch: 15},
      {wch: 20},
      {wch: 10},
      {wch: 15},
      {wch: 30},
      {wch: 10},
      {wch: 10},
      {wch: 8},
      {wch: 8},
      {wch: 15},
      {wch: 10},
      {wch: 10},
      {wch: 10},
      {wch: 15},
      {wch: 100}
    ];
    const wb = utils.book_new()
    utils.book_append_sheet(wb, ws, 'Лист 1')
    writeFileXLSX(wb,  'products.xlsx')
  }
}